import React from 'react'
import {
  CardWithTitle,
  InputField,
  Button
} from '@marcelorodcla/pds-ui'
import { useForm } from 'react-hook-form'

const ListingTerms = ({
  id,
  className = '',
  auth0UserId,
  onUpdate,
  values,
  onCreate,
  getAccessToken
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      ...values
    }
  })
  const onSubmit = async formData => {
    const currentAction = id === 0
      ? onCreate
      : onUpdate
    const currentId = id === 0 ? { } : { id }
    onUpdate && await currentAction(
      {
        auth0UserId,
        settings: {
          ...currentId,
          listingTerms: { ...formData }
        }
      },
      getAccessToken
    )

    location.reload()
  }

  return (
    <CardWithTitle
      title="Default Listing Terms"
      className={className}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label="Offer Price"
          type="text"
          error={errors?.title?.message}
          { ...register('minOfferPrice') }
        />
        <InputField
          label="Required Royalty"
          type="text"
          error={errors?.title?.message}
          { ...register('requiredRoyalty') }
        />
        <InputField
          label="Number of Licenses"
          type="text"
          error={errors?.title?.message}
          { ...register('numberLicenses') }
        />
         <Button
          isRounded={false}
          type="submit"
        >
          Save Listing Terms
        </Button>
      </form>
    </CardWithTitle>
  )
}

export default ListingTerms
