import axios from 'axios'
import {apiUriBase} from "@/configs/urls";

export const getSession = (accessToken) => {
  const config = {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  }

  return axios
    .get(`${apiUriBase}/session`, config)
    .then(res => res.data)
    .catch(err => console.error('Client error in getSession', err))
}
