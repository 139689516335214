// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t7viS8K3qrDW17fGtswg{height:40px;min-width:150px;display:flex;text-transform:uppercase;line-height:1;margin-top:8px}.t7viS8K3qrDW17fGtswg img{filter:brightness(0) invert(1) !important}.t0LJrTT1Xf5ySvGQmSZP{background:#0e1e32 !important;color:#fff}.tItnWhIqCpPx0BjyGjAQ{max-width:400px !important;font-size:15px;line-height:1.5}@media(max-width: 440px){.tItnWhIqCpPx0BjyGjAQ{max-width:300px !important}}`, "",{"version":3,"sources":["webpack://./src/pages/Assets/pages/assets-epub-txt/EpubAsset/components/ButtonBar/components/MaskingButton/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,eAAA,CACA,YAAA,CACA,wBAAA,CACA,aAAA,CACA,cAAA,CACA,0BACE,yCAAA,CACJ,sBACE,6BAAA,CACA,UAAA,CACF,sBACE,0BAAA,CACA,cAAA,CACA,eAAA,CCVC,yBDOH,sBAKI,0BAAA,CAAA","sourcesContent":["@import \"src/assets/sass/base/_breakpoints.sass\"\n\n.maskingBtn  \n  height: 40px\n  min-width: 150px\n  display: flex\n  text-transform: uppercase\n  line-height: 1\n  margin-top: 8px    \n  img\n    filter: brightness(0) invert(1) !important\n.isSelected\n  background: #0e1e32 !important\n  color: #fff\n.tooltip\n  max-width: 400px !important\n  font-size: 15px\n  line-height: 1.5\n  @include small-mobile\n    max-width: 300px !important  ","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maskingBtn": `t7viS8K3qrDW17fGtswg`,
	"isSelected": `t0LJrTT1Xf5ySvGQmSZP`,
	"tooltip": `tItnWhIqCpPx0BjyGjAQ`
};
export default ___CSS_LOADER_EXPORT___;
