import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Home from './Home'
import { AuthenticationGuard } from '@/components/wrappers/authentication/AuthenticationGuard'
import { PageLoader } from '@/components/molecules/loaders/PageLoader'
import { CallbackPage } from './Callback'
import { Route, Routes } from 'react-router-dom'
import WrapperSessionProvider from './authentication-session/WrapperSessionProvider'
import NotFound from './NotFound'
import { Logout } from './Logout'

const Index = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route
        path="/*"
        element={
          <AuthenticationGuard
            component={WrapperSessionProvider}
          />
        }
      />
      <Route path="/logout" element={<Logout />} />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Index
